@use "../../global" as *;

.index {
  .p-contact {
    border-bottom: 2px dashed $clP;
    &__text {
      font-size: 2.4rem;
      margin-left: calc(5% + 54px);
      line-height: 1.4;
      @include mq(sp) {
        font-size: 1.4rem;
        margin-left: calc(5%);
      }
    }
    &__link {
      position: relative;
      height: 35vh;
      &:after {
        content: "";
        display: inline-block;
        background-image: url(../img/common/arrow.svg);
        background-repeat: no-repeat;
        background-size: cover;
        width: 16px;
        height: 17px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0,-50%);
      }
      @include mq(sp) {
        height: 27vh;
        &:after {
          width: 12px;
          height: 14px;
        }
      }
    }
  }
}