@use "../global" as*;

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    // @include mq(sm) {
    //     scroll-padding-top: 100px;
    // }
}


body {
    box-sizing: border-box;
    position: relative;
    font-family: $fontF;
    font-size: 1.6rem;
    color: $clText;
    font-weight: 400;
    background-color: $clW;
    line-height: 1.4;
    overflow-x: hidden;
    display: flex;

    @include mq() {
        font-size: 1.4rem;
        flex-direction: column;
    }
}


a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    @include mq(sp) {
        pointer-events:all;
        cursor: pointer;
        text-decoration: none;
    }
}
a {
    color: $clText;
    transition: .2s;
    &:hover {
        opacity: .5;
        transition: .2s;

    }
}
img {
    height: auto;
    display: block;
}
.content {
    width: 85%;
    @include mq () {
        width: 100%;
    }
}

// * {
//   outline: 1px solid red !important;
// }