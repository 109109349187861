@use "../../global" as *;

.index {
  .p-kv {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq (sp) {
      flex-direction: column;
    }

    &__content {
      height: 51vh;
      width: 65%;
      padding: 0 5%;

      @include mq (sp) {
        width: 100%;
      }

    }

    &__content-wrapper {
      position: relative;

    }

    &__image {
      width: 40%;
      position: absolute;
      top: 50%;
      right: 0%;
      transform: translate(0, -50%);

      img {
        width: 100%;

      }
    }

    &__copy {
      width: 80%;

      img {
        width: 100%;
      }

    }

    &__link {
      width: 35%;

      // border: 2px dashed $clP;
      @include mq (sp) {
        display: flex;
        width: 100%;
        border-top: 1px dashed $clP;
        border-bottom: 1px dashed $clP;
      }

    }

    &__link-content {
      @include mq (sp) {
        width: 50%;

        &:first-child {
          border-right: 1px dashed $clP;
        }

      }

      a {
        height: 25.5vh;
        font-size: 2.8rem;
        padding: 0px 10% 0;
        &:after {
          height: 25.5vh;
        }

        @include mq (sp) {
          padding: 0px 8%;
          font-size: 2.0rem;
          height: 18vh;

        }

      }
    }


  }
}