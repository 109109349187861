@use "../global" as*;

.l-footer {
    background-color: $clP;
    padding: 0 24px;

    &__copyright {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        color: $clW;
        height: 5vh;
        @include mq(sp) {
            font-size: 1.2rem;
        }

    }

}