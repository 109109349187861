@use "../../global" as *;

.lower {


  form {
    width: 100%;
  }

  form p {
    margin-bottom: 32px;
  }

  form p .item-content {
    display: block;
    margin-bottom: 16px;

    @include mq () {
      margin-bottom: 12px;

    }
  }
  @include mq (sp) {
    .lower form p .item-content .title {
      font-size: 1.5rem;
    }
  }

  form p .required {
    background-color: #ac4a33;
    color: $clW;
    padding: 3px 8px;
    border-radius: 5px;
    margin-left: 12px;
  }

  form .wpcf7-form-control-wrap {
    width: 100%;
  }

  form .wpcf7-form-control {
    border: 2px solid $clBD;
    padding: 5px 10px;
    border-radius: 5px;
  }

  form .wpcf7-text {
    width: 30%;
    max-width: 300px;

    @include mq () {
      width: 40%;
    }

    @include mq (sp) {
      width: 80%;
      max-width: 100%;
    }
  }



  form .radio {
    border: none;
    padding: 0;
  }

  form .radio input {
    width: revert;
    margin-right: 10px;
  }

  form .radio label {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  form .radio .wpcf7-list-item {
    display: block;
  }

  form .wpcf7-textarea {
    width: 50%;
    max-width: 520px;

    @include mq () {
      width: 80%;
      max-width: 100%;
    }

    @include mq (sp) {
      width: 100%;
    }
  }

  form .wpcf7-submit {
    background-color: $clP;
    font-size: 2rem;
    width: 40%;
    max-width: 400px;
    margin: auto;
    padding: 15px 12px;
    color: $clW;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    border: none;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    transition: .2s;

    &:hover {
      opacity: 0.5;
      transition: .2s;
    }

    @include mq (sp) {
      width: 70%;
      padding: 10px 12px;
      margin-top: 20px;
    }
  }
}