@use "../../global" as *;

.index {
  .p-skill {
    height: 44vh;
    background-color: $clBG;
    @include mq (sp) {
      height: auto;
    padding: 40px 5%;
    }


    &__title {
      h3 {
        font-size: 1.8rem;
        margin-bottom: 16px;
        line-height: 1.4;

        span {
          font-size: 1.6rem;
          display: flex;
          align-items: center;
          margin-left: 5px;

          &:before {
            content: "";
            display: inline-block;
            background-color: $clP;
            background-repeat: no-repeat;
            background-size: cover;
            width: 14px;
            height: 2px;
            transform: translate(0, 50%);
            margin-right: 8px;
          }
        }

      }

      @include mq (sp) {
        h3 {
          font-size: 1.6rem;
          margin-left: 5px;

          span {
            font-size: 1.4rem;

            &:before {
              width: 10px;
              height: 2px;
            }
          }
        }
      }
    }

    &__wrapper {
      display: flex;
      gap: 60px 120px;
      margin-left: calc(5% + 32px);
      flex-wrap: wrap;

      @include mq (sp) {
        gap: 30px 30px;
        margin-left: calc(5% + 5px);
      }
    }

    &__image {
      width: 120px;

      img {
        width: 100%;

      }

      @include mq (sp) {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
      }
    }

    &__container {
      display: flex;
      align-items: center;
      gap: 70px;

      @include mq (sp) {
        gap: 20px 0;
        flex-direction: column;
      }
    }

    &__link-list {
      li {
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }

        a {
          font-size: 1.4rem;
          border-bottom: 1px dashed $clP;
          padding: 0 4px 4px;
          width: 120px;
          display: flex;
          align-items: center;

          &:hover {
            color: $clP;
            opacity: 0.5;
          }
          span {
            width: 7px;
            height: 7px;
            margin-right: 12px;
          }
        }

      }

      @include mq (sp) {
        li {
          margin-bottom: 10px;
          a {
            font-size: 1.4rem;
            width: 120px;
            padding: 0 5px 6px;
            

            &:before {
              width: 8px;
              height: 9px;
              margin-right: 10px;
            }
          }
        }

      }
    }

  }
}