@use "../../global" as *;

.index {
  .p-news {
    background-color: $clBG;
    padding-top: 100px;
    margin-top: -60px;
    position: relative;
    padding: 140px 24px 80px;

    @include mq (tb) {
      padding: 140px 24px 60px;
    }

    @include mq (sp) {
      margin-top: -30px;
      padding: 100px 24px 60px;
    }

    &__wrapper {
      max-width: 1240px;
      margin: auto;
      position: relative;

      &:before {
        content: "";
        display: block;
        width: min(18vw, 250px);
        height: min(18vw, 250px);
        min-width: 230px;
        min-height: 220px;
        background-image: url(../img/index/news_decoration.png);
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        bottom: max(-20vw, -270px);
        right: 0;

      }

      @include mq (sp) {
        max-width: 540px;

        &:before {
          display: none;
        }
      }
    }

    &__inner {
      background-color: $clW;
      max-width: 880px;
      margin: auto;
      border-radius: 20px;
      padding: 0 24px 40px;

      @include mq (tb) {
        max-width: 740px;
      }

      @include mq (sp) {
        padding: 0 24px 48px;
        margin-bottom: 24px;
      }
    }

    &__title {
      transform: translate(0, -50%);
      margin-bottom: 15px;

      @include mq(tb) {
        margin-bottom: 0px;
      }
    }

    &__feed-list {
      margin: auto;
      max-width: 780px;

      li {
        margin-bottom: 16px;
        border-bottom: 1px dashed $clBD;
        padding-bottom: 10px;
        display: flex;

        &:last-child {
          border-bottom: none;
        }

        @include mq (tb) {
          flex-direction: column;
        }

        @include mq (sp) {
          &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }
      }

      .date {
        font-size: 1.6rem;
        color: $clP;
        letter-spacing: 0.1em;
        padding-right: 54px;
        position: relative;

        &:after {
          content: "";
          display: inline-block;
          background-color: $clP;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          position: absolute;
          right: 24px;
          top: 50%;
          transform: translate(0%, -50%);
        }

        @include mq (tb) {
          &:after {
            display: none;
          }

        }

      }

      .ttl {
        a {
          font-size: 1.6rem;
          color: $clText;
          letter-spacing: 0.1em;
          display: block;
          max-width: 650px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @include mq (tb) {
          a {
            max-width: 100%;
          }
        }
      }
    }

    &__button {
      transform: translate(0%, -50%);

      @include mq (sp) {
        transform: translate(0, 0);
      }
    }
  }
}