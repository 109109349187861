@use "../../global" as *;

.c-arrow-top {
    display: inline-block;
    color: $clP;
    line-height: 1;
    position: relative;
    width: 9px;
    height: 9px;
    transform: rotate(-135deg);
    transition: color 0.2s 0.4s;
    transition-delay: 0s;

    &:before,
    &:after {
        content: "";
        position: absolute;
        background: currentColor;
        border-radius: 0.1em;
    }

    &:before {
        top: 0;
        left: 0;
        right: 0;
        height: 2px;
    }

    &:after {
        top: 0;
        right: 0;
        bottom: 0;
        width: 2px;
    }
}
.c-arrow{
    display: inline-block;
    color: $clP;
    line-height: 1;
    position: relative;
    width: 9px;
    height: 9px;
    transform: rotate(45deg);
    transition: color 0.2s 0.4s;
    transition-delay: 0s;

    &:before,
    &:after {
        content: "";
        position: absolute;
        background: currentColor;
        border-radius: 0.1em;

    }

    &:before {
        top: 0;
        left: 0;
        right: 0;
        height: 2px;
    }

    &:after {
        top: 0;
        right: 0;
        bottom: 0;
        width: 2px;
    }
}