@use "../../global" as *;

.lower {
  .p-error {
    padding: 20px 0px 60px;

    &__button {
      margin-left: calc(5% + 32px);

      a {
        width: 100%;
        padding: 20px 36px 20px 24px;
        font-size: 1.6rem;
        border: 2px dashed $clP;
        max-width: 250px;

      }

      @include mq (sp) {
        margin-left: calc(5% + 5px);

        a {
          font-size: 1.4rem;
          border: 1px dashed $clP;
          padding: 18px 50px 18px 24px;
        }
      }

    }

    &__text {
      margin-left: calc(5% + 32px);
      line-height: 1.45;
      margin-bottom: 32px;

      @include mq (sp) {
        margin-left: calc(5% + 5px);
        line-height: 1.5;

        .br {
          display: none;
        }

      }
    }
  }
}