@use "../../global" as *;

.pd {
    padding-left: 24px;
    padding-right: 24px;
}
.flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.mt-01 {
    margin-top: 24px;
}
.pt-01  {
    padding-top: 24px;
}
.pb-01 {
    padding-bottom: 24px;
}