//-----------------------------------カラー
//----------メインカラー
$clP: #222222; //プライマリ★
$clS: #E56EAF; //セカンダリ★
$clW: #ffffff; //白（★）
$clA: #a9865f; //アクセント（★）
$cly: #FFFBE6;
$clpink: #F4C4CF;
// 背景カラー
$clBG: #F7F7F7; //★
// $clBG2:rgba(126, 153, 140, 0.05); //★
// 枠線カラー
$clBD: #C4C3C2; //★
// $clBD2: #24211B1A; //★ß
//文字カラー
$clText: #222; //本文★
$clText2: #53350D; //薄め本文★
$clTextW: #FFF; 

// $clText2: #f4e2c5; //薄め本文★
$clTextR: #ac4a33; //赤文字（強調）（★）
$clTextM: #f8f472; //マーカー（★）
// リンク文字カラー
$clLink: #000; //（★）
$clLinkH: #20b29569; //（★）
// セレクション文字カラー
$clSelect: #7E998C; //（★）
//-----------------------------------フォント
$fontF: 'Sawarabi Gothic', sans-serif; //（★）
$fontS: 'Noto Sans', sans-serif; //（★）
$fontSize: 16px; //PC本文（★）
$fontSizeSP: 14px; //SP本文（★）
//▼ Noto Sans JP の 標準のfont-weight（★）
//$fontW: 100; //Thin
//$fontW: 300; //Light
// $fontW: 400; //Regular
$fontW: 600; //Medium
//$fontW: 700; //Bold
//$fontW: 900; //Black
$ltsp: .05em; //標準のレタースペーシング（★）
//-----------------------------------線の太さ
$bdW: 2px; //★
//-----------------------------------フェード速度
$speedFast: .2s ease-out; //（★）
$speedSlow: .5s ease-out; //（★）
//-----------------------------------opacityの薄さ
$opacity: .4; //（★）