@use "../../global" as *;

.lower {
  .p-lower {
    &__page-title {
      position: relative;
      padding: 200px 5%;
      border-bottom: 2px dashed $clP;

      @include mq () {
        padding: 150px 5%;
      }

      @include mq (sp) {
        padding: 120px 5%;

      }

      h2 {
        font-size: 5.2rem;
        letter-spacing: 0.01em;
      }

      @include mq () {
        h2 {
          font-size: 4.8rem;
        }

      }

      @include mq (sp) {

        h2 {
          font-size: 3.2rem;
        }
      }
    }

    h3 {
      font-size: 2.4rem;
      display: flex;
      align-items: center;
      margin-left: 5%;
      margin-bottom: 24px;
      font-family: $fontS;
      font-weight: 600;

      &:before {
        content: "";
        display: inline-block;
        background-image: url(../img/common/c-h2_icon.svg);
        background-size: contain;
        background-repeat: no-repeat;
        width: 17px;
        height: 10px;
        margin-right: 10px;
      }

      .brSP {
        display: none;
      }

      @include mq (sp) {
        font-size: 2.0rem;
        margin-bottom: 16px;
        margin-left: 0;
        line-height: 1.3;

        &:before {
          width: 13px;
          height: 12px;
          transform: translate(0);
          margin-right: 14px;
        }

        .brSP {
          display: block;
        }
      }

    }

    h4 {
      font-size: 2.0rem;
      padding-left: 1em;
      position: relative;

      &:before {
        content: "";
        display: block;
        background-color: $clP;
        width: .5em;
        height: 2px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0%, -50%);
      }

    }

    .section-body {
      // padding-bottom: 60px;

      @include mq () {
        // max-width: 940px;
        margin-left: 0;
      }
    }

    &__image {
      width: 28%;
      max-width: 320px;
      position: absolute;
      top: 50%;
      left: 45%;
      transform: translate(-50%, -50%);

      img {
        width: 100%;

      }

      @include mq () {
        width: 30%;
        left: 40%;

      }

      @include mq (sp) {
        width: 50%;
        max-width: 160px;
        left: 60%;
      }

    }

    //wordpress書き換え

    .wp-block-button {
      position: relative;

      a {
        width: 100%;
        padding: 18px 0px 18px 18px;
        font-size: 1.6rem;
        border: 2px dashed $clP;
        max-width: 250px;
        border-radius: 0;
        background-color: $clW;
        color: $clP;
        transition: color 0.2s 0.1s;
        font-weight: 600;
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60vw;
        max-width: 250px;

        @include mq () {
          font-size: 1.4rem;
        }

        .c-arrow {
          left: -20px;
        }

        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $clP;
          clip-path: inset(0 100% 0 0);
          transition: clip-path 0.3s;
          z-index: -1;
        }

        &:hover {
          position: relative;
          z-index: 2;
          color: $clW;
          transition: color 0.2s 0.3s;
          transition-delay: 0.05s;
          opacity: 1;

          .c-arrow {
            color: $clW;
            transition: color 0.2s 0.3s;
            transition-delay: 0.05s;
          }
        }

        &:hover::after {
          content: "";
          display: block;
          clip-path: inset(0 0% 0 0);
        }

      }
    }

    .wp-block-image {
      a {
        transition: .2s;

        &:hover {
          opacity: 0.5;
          transition: .2s;
        }
      }
    }

  }
}