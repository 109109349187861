@use "../../global" as *;

.lower {
  .p-lower-news {
    &__wrapper {
      display: flex;
      

      @include mq (sp) {
        flex-direction: column;
      }
    }

    &__inner {
      width: 80%;
      padding: 3% 2% 3% 0;
      padding-right: 2%;
      border-right: 2px dashed $clP;

      @include mq (sp) {
        width: 100%;
        margin-bottom: 40px;
        border-right: none;
        padding-right: 0;
      }
    }
    &__contents {
      display: flex;
      flex-wrap: wrap;
      gap: 40px 0;
    }

    &__archive-list {
      position: relative;
      margin: 0 2.5%;
      width: 30%;
      &:nth-child(3n + 1) {
        margin-left: 0;

      }
      &:nth-child(3n) {
        margin-right: 0;

      }
      @include mq () {
        width: 48%;
        margin: 0;
        margin-right: 0;
        &:nth-child(2n) {
          margin-right: 0;
  
        }


      }
      @include mq (sp) {
        width: 100%;
        margin: 0;
      

      }

      // &:nth-child(1n + 1)::before {
      //   content: "";
      //   display: inline-block;
      //   border-right: 1px dashed $clP;
      //   width: 1px;
      //   height: 88%;
      //   position: absolute;
      //   top: 50%;
      //  right: 0;
      //  transform: translate(0%,-50%);
      // }
      // &:nth-child(3n)::before {
      //   content: "";
      //   border-right: none;
      // }

    }
    &__archive-image {
      max-width: 300px;
      margin-bottom: 12px;
      a {
        transition: .2s;
        img {
          width: 100%;
        }
        &:hover {
          opacity: .5;
          transition: .2s;
        }
      }

    }

    &__archive-category {
      display: inline-block;
      margin-left: 10px;
      @include mq (sp) {
        line-height: 2.1;
        
      }
      ul {
        list-style: disc;
        padding-left: 20px;
      }
      li {
        margin-bottom: 6px;
        @include mq(sp) {
          margin-bottom: 0;
        }
      }

      a {
        font-size: 1.2rem;
        border-bottom: 1px dashed $clP;
        padding: 0 2px 2px;
        font-weight: 700;
        &:hover {
          color: $clP;
          opacity: 0.5;
        }
      }
    }

    &__archive-title {
      h3 {
        font-weight: bold;
        color: $clP;
        font-size: 2.0rem;
        margin-bottom: 10px;
        height: 50px;
      }
     

      @include mq () {
        font-size: 1.6rem;
      }
    }

    &__archive-body {
      margin-bottom: 16px;

    }

    &__archibe-link {
      text-align: right;

      a {
        margin-top: 8px;
        color: $clP;
        text-decoration: revert;

        &:hover {
          text-decoration: none;

        }
      }
    }

    &__article {
      margin-bottom: 48px;
      .p-lower-news__entry-title {
        font-size: 2.4rem;
        padding: 14px 20px;
        border-radius: 10px;
        margin-left: 0;
        margin-bottom: 12px;

        @include mq (sp) {
          font-size: 1.6rem;
          padding: 12px 18px;
        }
      }
    }

    // &__entry-date {
    //   display: block;
    //   border-bottom: solid 1px;
    //   margin-bottom: 16px;
    //   padding-bottom: 12px;
    // }

    &__article-text {
      letter-spacing: .1em;
      line-height: 1;
      margin-bottom: 10px;
      p {
        line-height: 1.4em;
      }
    }

    &__article-info {
      background: $clBG;
      padding: 16px;
      margin-top: 24px;
      border-radius: 10px;

      span {
        display: inline-block;
        margin: 0 .5em;
      }
    }

    &__entry-navi {
      a {
        color: $clP;
        font-weight: bold;
        text-decoration: underline;
      }

      a[rel="prev"]::before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 8px 8px 0;
        border-color: transparent $clP transparent transparent;
        vertical-align: middle;
        margin-right: 8px;
      }

      a[rel="next"]::after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0 8px 8px;
        border-color: transparent transparent transparent $clP;
        vertical-align: middle;
        margin-left: 8px;
      }

    }

    &__button {
      position: relative;

      a {
        width: 100%;
        padding: 10px 0px 10px 12px;
        font-size: 1.6rem;
        border: 2px dashed $clP;
        max-width: 250px;
        border-radius: 0;
        background-color: $clW;
        color: $clP;
        transition: color 0.2s 0.1s;
        font-weight: 600;
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 200px;

        @include mq () {
          font-size: 1.4rem;
        }

        .c-arrow {
          left: -12px;
        }

        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $clP;
          clip-path: inset(0 100% 0 0);
          transition: clip-path 0.3s;
          z-index: -1;
        }

        &:hover {
          position: relative;
          z-index: 2;
          color: $clW;
          transition: color 0.2s 0.3s;
          transition-delay: 0.05s;
          opacity: 1;

          .c-arrow {
            color: $clW;
            transition: color 0.2s 0.3s;
            transition-delay: 0.05s;
          }
        }

        &:hover::after {
          content: "";
          display: block;
          clip-path: inset(0 0% 0 0);
        }

      }
    }

    // サイドバー
    &__sidebar {
      font-weight: bold;
      color: $clP;
      font-size: 1.6rem;
      width: 20%;
      padding: 3% 0 3% 2%;
      

      @include mq () {
        font-size: 1.4rem;
      }

      @include mq (sp) {
        width: 100%;
        padding-left: 0;
      }

      section:first-child h3 {
        margin-top: 0;
      }

      h3 {
        margin: 24px 0px 12px;
        font-size: 1.8rem;
        color: $clP;

        @include mq (sp) {
          font-size: 1.6rem;
        
        }
      }

    }

    &__search {
      display: flex;
      justify-content: space-between;
      // @include mq () {
      //   width: 80%;
      // }
    }

    &__search-input {
      border-radius: 5px;
      border: 2px solid $clBD;
      padding: 5px 5px;
      width: 76%;

    }

    &__search-button {
      background-color: $clP;
      color: $clW;
      padding: 5px 0;
      border-radius: 5px;
      width: 22%;
      text-align: center;
      font-size: 1.4rem;
    }

    &__list-normal {
      li {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          text-decoration: revert;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

  }
}