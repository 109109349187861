@use "../../global" as *;

.c-h2-style {
   font-size: 3.2rem;
   margin-bottom: 16px;
   display: flex;
   align-items: center;
   margin-left: 5%;
   margin-bottom: 24px;
   font-family: $fontS;
   font-weight: 600;

   &:before {
      content: "";
      display: inline-block;
      background-image: url(../img/common/c-h2_icon.svg);
      background-size: cover;
      width: 14px;
      height: 12px;
      margin-right: 16px;
   }

   // @include mq () {
   //    font-size: 4.8rem;

   //    &:before {
   //       width: 21px;
   //       height: 19px;
   //    }
   // }

   @include mq (sp) {
      font-size: 2.4rem;
      margin-left: 0;

      &:before {
         width: 13px;
         height: 12px;
         transform: translate(0);
         margin-right: 14px;
      }
   }
}