@use "../../global" as *;

.c-button {
    position: relative;

    a {
        position: relative;
        z-index: 2;
        transition: color 0.2s 0.1s;
        font-family: $fontS;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
            position: relative;
            z-index: 2;
            color: $clW;
            transition: color 0.2s 0.3s;
            transition-delay: 0.05s;
            opacity: 1;
            .c-arrow-top {
                color: $clW;
                transition: color 0.2s 0.3s;
                transition-delay: 0.05s;
            }
            .c-arrow {
                color: $clW;
                transition: color 0.2s 0.3s;
                transition-delay: 0.05s;
            }
            @include mq () {
                color: $clP;
            }
        }

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $clP;
            clip-path: inset(0 100% 0 0);
            transition: clip-path 0.3s;
            z-index: -1;
        }

        &:hover::after {
            content: "";
            display: block;
            clip-path: inset(0 0% 0 0);
            @include mq () {
                display: none;
            }
        }
       
    }
}

// .c-button {
//     position: relative;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     font-family: $fontS;
//     font-weight: 600;

//     &:before {
//         content: "";
//         display: block;
//         background-color: $clP;
//         width: 100%;
//         position: absolute;
//         top: 0;
//         left: 0;
//         clip-path: inset(0 100% 0 0);
//         opacity: 1;
//         transition: 0.4s;
//         z-index: -1;
//     }

//     &:after {
//         content: "";
//         display: inline-block;
//         background-image: url(../img/common/arrow.svg);
//         background-repeat: no-repeat;
//         background-size: cover;

//     }

//     &:hover::before {
//         clip-path: inset(0 0 0 0);
//         z-index: -1;

//     }

//     &:hover {
//         color: $clW;

//         &:after {
//             background-image: url(../img/common/arrow-white.svg);
//             position: relative;
//         }
//     }

//     @include mq () {
//         &:before {
//             background-color: $clW;
//         }

//         &:hover {
//             color: initial;
//         }

//         &:hover::after {
//             background-image: url(../img/common/arrow.svg) !important;
//         }
//     }

// }