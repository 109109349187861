@use "../global" as*;

.l-header {
    background-color: $clW;
    padding-top: 70px;
    top: 0;
    z-index: 3;
    width: 15%;
    border-right: 2px dashed $clP;

    @include mq () {
        width: 100%;
        height: auto;
        padding: 14px 24px 32px;
        border-right: none;
    }

    &__wrapper {
        text-align: center;

        @include mq () {
            position: relative;
        }
    }

    &__logo {
        max-width: 60px;
        line-height: 0;
        margin: auto;
        margin-bottom: 20px;

        a {
            line-height: 0;

            &:hover {
                opacity: 0.4;
            }

            img {
                width: 100%;
            }
        }

        @include mq () {
            position: absolute;
            left: 0;
            top: 50%;
            z-index: 999;
            max-width: 50px;
            margin-bottom: 0;
        }

    }

    &__nav-inner {
        @include mq () {
            position: fixed;
            top: 0;
            right: 0;
            z-index: 19;
            width: 100%;
            height: 100vh;
            background-color: $clW;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s, visibility 0.5s;
        }
    }

    &__nav-main {
        @include mq () {
            margin-top: 180px;
        }
    }


    &__nav-list {

        li {
            margin-bottom: 14px;

            a {
                color: $clText;
                text-decoration: none;
                font-size: 1.6rem;
                letter-spacing: 0.16em;
                font-family: $fontS;
                font-weight: 600;

                &:hover {
                    color: $clP;
                    opacity: 0.5;
                }

            }
        }

    }

    &.is-open {
        .l-header__nav {
            opacity: 1;
            visibility: visible;

            .l-header__nav-main {
                opacity: 1;
                visibility: visible;
            }

            .l-header__nav-inner {
                opacity: 1;
                visibility: visible;
            }
        }
    }

}

/*---------------------------------------
ハンバーガーメニュー
---------------------------------------*/
.l-header__ham {
    display: none;

    @include mq () {
        display: block;
        line-height: 0;
        text-align: right;

        &-btn {
            width: 34px;
            height: 25px;
            position: relative;
            // background-color: $clP;
            border: none;
            z-index: 25;

            &:after {
                content: "Menu";
                position: absolute;
                bottom: -6px;
                left: 50%;
                transform: translateX(-50%);
                color: $clText;
                font-size: 1.2rem;
                font-weight: 700;
            }

            span {
                background-image: url(../img/common/hamburger_open-icon.svg);
                background-repeat: no-repeat;
                background-size: cover;
                display: block;
                width: 30px;
                height: 25px;
                transition: .6s;
                margin: auto;

            }
        }
    }
}

.l-header {
    &.is-open {
        .l-header__ham {
            .l-header__ham-btn {
                background-color: initial;

                &:after {
                    content: "Close";
                }

            }
        }
    }
}